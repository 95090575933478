import React, { Component } from "react";
import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"
import { Link } from "react-router-dom";
class SignUpFinal extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  render() {

    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    return (
      <div className="SignUp">

      <img alt="musea logo"
        srcSet={logoSrcSet}
        sizes="144px"
        className="image"
        src={logoBlack}
        style={{width: "144"}}
        />

      <div class="text-block-24">
          Congratulations!
          <br/>
          You can now sign in and place orders with Musea Lab!
      </div>
        <Link
            to="/lab-orders"
            style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            marginLeft: '3px',
            }}
            className="link-4"
          >
            Back to Home 
        </Link>

      </div>
    );
  }
}

export default SignUpFinal;

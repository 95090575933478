import React, { Component } from "react";
import Auth from "../modules/Auth";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import validatedCheck from "../img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";

class LoginInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      password:'',
      errorState: false,
      emailError: false,
      updatingLogin: false,
      passwordError: false,
      passwordsMatch: false
    };
    this.emailValid = this.emailValid.bind(this);
    this.throwError = this.throwError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)    
  };

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      console.log("Props updated:", this.props.email);
      this.setState({ email: this.props.email });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      emailError: false,
      passwordError: false
    });
  }
  

  emailValid(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleSubmit(e) {
    e.preventDefault()    
    this.setState({
      updatingLogin: true
    })
    var token = Auth.getToken()
    axios
    .patch(`/api/v2/update_user_email`,
          {email: this.state.email,
            password: this.state.password,
          }, {headers: {token: token}})
          .then(res => {
            console.log('res',res.data)
            console.log('called from here');

            this.setState({
              updatingLogin: false,
            })
            this.notify(`You've successfully updated your email address`)
          })
          .catch((err)=>{
            if (err.response) {
              if (err.response.data.error.includes("already")) {
                this.setState({ emailError: true });
              } else {
                this.setState({ passwordError: true });
              }
              console.error('Error message:', err.response.data.error);  // Correct way to get error message
             
            } else {
              console.error('Request failed', err);
            }
            this.setState({updatingLogin:false})
          })
  }

  notify(msg) {
    toast(`${msg}`, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, style:{color:"green"}});
  }
  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      query: "",
      errorState: true,
      errorMessage: errorMessage
    })
  }

  render() {
    const { redirectToLogin, email, passwordsMatch, emailError, passwordError, updatingLogin,password } = this.state

    if (redirectToLogin) {
      Auth.deauthenticateUserAll();
      return <Redirect push to={{ pathname: "/login", state: {} }} />;
    }

    let updateUserButton, emailErrorField, passwordErrorField, emailValidImage, passwordValidImage, emailValidColor, passwordValidColor
    if (this.emailValid(email)  && password.length>=8 && !emailError) {
      updateUserButton = <div>
        <input type="submit"
          className="button-11 w-button"
          onClick={(e) => { this.handleSubmit(e) }}
          value="CHANGE LOGIN" />
      </div>
    } else {
      console.log('email',email,passwordsMatch, emailError);
      
      updateUserButton = <div>
        <input type="submit"
          className="button-11 w-button"
          disabled
          style={{ opacity: '0.5' }}
          value="CHANGE LOGIN" />
      </div>
    }

    if (emailError) {
      emailErrorField = <div>
        <p className="w-form-fail"
          name="emailError"
          style={{
            marginTop: '5px',
            display: 'inline-block',
            width: '75%',
            color: 'red',
          }}>

      This email is already assigned to your account. Enter a new email above and your current password below to update your email address.
        </p>
      </div>
      emailValidImage = ""

    } else if (!emailError && this.emailValid(email)) {
      emailErrorField = <div></div>
      emailValidImage = "url('" + validatedCheck + "')"
      emailValidColor = 'green'
    } else {
      emailErrorField = <div> </div>
      emailValidImage = ""
      emailValidColor = 'red'
    }

    if (passwordError) {
      passwordErrorField = <div>
        <p className="w-form-fail"
          name="passwordMatchError"
          style={{
            marginTop: '5px',
            display: 'inline-block',
            color: 'red'
          }}>

          Incorrect Password. Please enter correct password.
                </p>
      </div>
      passwordValidImage = ""
    } else if (!passwordError && passwordsMatch) {
      console.log("error field is a check")
      passwordErrorField = <div></div>
      passwordValidImage = "url('" + validatedCheck + "')"
      passwordValidColor = 'green'
    } else {
      passwordErrorField = <div> </div>
      passwordValidImage = "" + validatedCheck + "')"
      passwordValidColor = 'red'
    }


    if (updatingLogin) {
      return (
        <div className="w-form">
          <div className="form-4" style={{ marginTop: '20px', marginBottom: '50px', fontWeight: '700' }}>
            updating login info...
          </div>
        </div>
      )
    } else {
      return (
        <div className="w-form">
            <ToastContainer position="top-right"  autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
          <div className="form-4">
            <input type="text"
              className="text-field-8 w-input"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              style={{
                background: emailValidImage + ' 98% 50% /20px no-repeat',
                color: emailValidColor
              }}
              placeholder="New Login Email" />
            {emailErrorField}

            <input type="password"
              name="password"
              value={this.state.password}
              autoComplete="off" 
              onChange={this.handleChange}
              className="text-field-9 w-input"
              placeholder="Password" />
            {passwordErrorField}

            {updateUserButton}
            <small> Want to update your password? Visit <a href="https://app.musealab.com/forgot-password" target="_blank"> https://app.musealab.com/forgot-password </a> or Log out and click "Reset" link next to "Forgot Password?" under the Sign In button. </small>
          </div>
        </div>
      )
    }
  }
}

export default LoginInfoForm;

